import { useContext, useState } from 'react';
import FunnelContext from '../context/FunnelContext';

export function useFunnelContext(
  props,
  defaultTubIndex = 0,
  defaultCategory = 'subscriptions'
) {
  const contextFunnel = useContext(FunnelContext);
  const {
    path,
    location: { href: funnelUrl },
    data: { funnelPageData },
    pageDesign = 'design1'
  } = props;

  const [tubIndex, setTubIndex] = useState(defaultTubIndex);
  const [currentTubIndex, setCurrentTubIndex] = useState(0);
  const [productImageIndex, setProductImageIndex] = useState(0);
  const defaultProduct =
    defaultCategory === 'subscriptions'
      ? `subscription_${tubIndex}`
      : `onetime_${tubIndex}`;
  const [currentCategory, setCurrentCategory] = useState(defaultCategory);
  const [currentProduct, setCurrentProduct] = useState(defaultProduct);
  const [frequency, setFrequency] = useState('1:month');
  const [billingFrequency, setBillingFrequency] = useState(null);
  const [bumpOfferChecked, setBumpOfferChecked] = useState(false);
  const [tubIndexChecked, setTubIndexChecked] = useState(false);
  const [bumpOfferIndex, setBumpOfferIndex] = useState(0);
  const [bumpOfferArray, setBumpOfferArray] = useState([]);
  const [currentTub, setCurrentTub] = useState({});
  const [subscriptionList, setSubscriptionList] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [calendarDate, setCalendarDate] = useState(null);
  const [loadingCover, setLoadingCover] = useState(true);
  const [showSavingsPopup, setShowSavingsPopup] = useState(false);
  const [showUpsellPopup, setShowUpsellPopup] = useState(false);
  const [openUpsellPopupAnimation, setOpenUpsellPopupAnimation] = useState(
    false
  );
  const [submittedToCart, setSubmittedToCart] = useState(false);
  const [currentLayer, setCurrentLayer] = useState(`layer-0`);
  const [previousLayer, setPreviousLayer] = useState([]);
  const [localCartItems, setLocalCartItems] = useState([]);
  const [funnelUpdated, setFunnelUpdated] = useState(false);
  const [answers, setAnswers] = useState({});
  const [buttonClicked, setButtonClicked] = useState(false);
  const [tokenResultsLoaded, setTokenResultsLoaded] = useState(false);
  const [navClicked, setNavClicked] = useState(false);
  const [dogSize, setDogSize] = useState('');
  const [tag, setTag] = useState('');
  const [extraObjects, setExtraObjects] = useState({
    ...JSON.parse(funnelPageData?.productSelector?.extra || '{}'),
    is_tub_index_checked: 'no',
    currentCategory,
    bumpOfferCategory: currentCategory
  });
  const [accordionActiveIndex, setAccordionActiveIndex] = useState(0);

  return {
    ...contextFunnel,
    ...funnelPageData?.productSelector,
    path: path,
    funnelUrl: funnelUrl,
    pageDesign,
    currentCategory,
    setCurrentCategory,
    tubIndex,
    setTubIndex,
    currentProduct,
    setCurrentProduct,
    extraObjects,
    setExtraObjects,
    currentTubIndex,
    setCurrentTubIndex,
    frequency: frequency,
    setFrequency: setFrequency,
    billingFrequency: billingFrequency,
    setBillingFrequency: setBillingFrequency,
    bumpOfferChecked: bumpOfferChecked,
    setBumpOfferChecked: setBumpOfferChecked,
    bumpOfferIndex: bumpOfferIndex,
    setBumpOfferIndex: setBumpOfferIndex,
    bumpOfferArray: bumpOfferArray,
    setBumpOfferArray: setBumpOfferArray,
    submittedToCart: submittedToCart,
    setSubmittedToCart: setSubmittedToCart,
    showSavingsPopup: showSavingsPopup,
    setShowSavingsPopup: setShowSavingsPopup,
    showUpsellPopup: showUpsellPopup,
    setShowUpsellPopup: setShowUpsellPopup,
    openUpsellPopupAnimation,
    setOpenUpsellPopupAnimation,
    currentLayer: currentLayer,
    setCurrentLayer: setCurrentLayer,
    previousLayer: previousLayer,
    setPreviousLayer: setPreviousLayer,
    answers: answers,
    setAnswers: setAnswers,
    productImageIndex: productImageIndex,
    setProductImageIndex: setProductImageIndex,
    tubIndexChecked: tubIndexChecked,
    setTubIndexChecked: setTubIndexChecked,
    localCartItems: localCartItems,
    setLocalCartItems: setLocalCartItems,
    funnelUpdated: funnelUpdated,
    setFunnelUpdated: setFunnelUpdated,
    currentTub: currentTub,
    setCurrentTub: setCurrentTub,
    subscriptionList: subscriptionList,
    setSubscriptionList: setSubscriptionList,
    userDetails: userDetails,
    setUserDetails: setUserDetails,
    deliveryDate: deliveryDate,
    setDeliveryDate: setDeliveryDate,
    calendarDate: calendarDate,
    setCalendarDate: setCalendarDate,
    loadingCover: loadingCover,
    setLoadingCover: setLoadingCover,
    buttonClicked: buttonClicked,
    setButtonClicked: setButtonClicked,
    navClicked: navClicked,
    setNavClicked: setNavClicked,
    tokenResultsLoaded,
    setTokenResultsLoaded,
    accordionActiveIndex,
    setAccordionActiveIndex,
    dogSize,
    setDogSize,
    tag,
    setTag
  };
}
