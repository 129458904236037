import React, { useState, useEffect } from 'react';
import { getParamFromQuery } from '../utils/getParamFromQuery';
import { builder, BuilderComponent } from '@builder.io/react';
import { FunnelProvider } from '../context/FunnelContext';
import axios from 'axios';
import loadable from '@loadable/component';
import ProductSelectorPreview from '../components/ProductSelectorPreview/ProductSelectorPreview';
import { useFunnelContext } from '../hooks/useFunnelContext';
import '../../static/css/funnel.css';

import '../builder-settings';

const Helmet = loadable(() => import('react-helmet'));

builder.init(process.env.GATSBY_BUILDER_API_KEY);

const FunnelPreview = props => {
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [path, setPath] = useState(null);
  const [funnelDesign, setFunnelDesign] = useState('design2');
  const [builderContentJson, setBuilderContentJson] = useState(null);
  const [productSelectorData, setProductSelectorData] = useState(null);
  const [currentCategory, setCurrentCategory] = useState('subscriptions');
  const [currentTubIndex, setCurrentTubIndex] = useState(0);
  const [contextProps, setContextProps] = useState({
    path,
    location: { href: null },
    data: { funnelPageData: null }
  });

  const fetchCheckoutData = async productSelectorId => {
    return await axios
      .post(`/.netlify/functions/funnel-data`, { id: productSelectorId })
      .then(res => {
        return res.data;
      });
  };

  useEffect(() => {
    const path = getParamFromQuery('url');
    setPath(path);
    builder
      .get('funnel', { url: path })
      .promise()
      .then(res => {
        setCurrentCategory(res.data.currentCategory || 'subscriptions');
        setFunnelDesign(res.data.design || 'design_2');
        setCurrentTubIndex(res.data?.settings?.defaultTubIndex || 0);
        fetchCheckoutData(res.data.products.id).then(res => {
          setProductSelectorData(res);
          setContextProps({
            path,
            location: { href: path },
            data: {
              funnelPageData: {
                productSelector: {
                  ...res,
                  extra: JSON.stringify(res.extra)
                }
              }
            },
            pageDesign: funnelDesign
          });
          setLoadingPreview(false);
        });
        setBuilderContentJson(res);
      });
  }, [funnelDesign]);

  const context = useFunnelContext(
    contextProps,
    currentTubIndex,
    currentCategory
  );

  if (loadingPreview) {
    return <>please wait, preview is still fetching the data...</>;
  }

  return productSelectorData ? (
    <FunnelProvider value={context}>
      <Helmet
        bodyAttributes={{
          class: `petlab-funnel-1 ${funnelDesign}`
        }}
        htmlAttributes={{
          lang: 'en'
        }}
      >
        <title>
          {builderContentJson && builderContentJson.data?.siteMeta?.title}
        </title>
        <meta
          name="description"
          content={
            builderContentJson && builderContentJson.data?.siteMeta?.description
          }
        />
        <meta name="funnel-id" content={builderContentJson?.id} />
        <meta
          name="product-selector-id"
          content={builderContentJson?.data?.products?.id}
        />
        <meta
          name="magic-key"
          content="t54cdW3cbU45TWPRtYGUR4fyBnWHe49jWeuqdRStfAvELctRyfyXLmDteF9Cf9jhPsyzDfkcCK5DNU6Pr87HU8tgNKBDDmeHDFQn"
        />
      </Helmet>
      <BuilderComponent model="funnel" content={builderContentJson} />
      <ProductSelectorPreview data={productSelectorData} path={path} />
    </FunnelProvider>
  ) : null;
};

export default FunnelPreview;
