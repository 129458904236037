import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import * as S from './ProductSelectorPreview.styles';

const PriceStrike = {
  textDecoration: `line-through`
};

function ProductSelectorPreview({ data, path }) {
  // please don't remove this console.log, this is intentional and needed
  console.log(data);

  const [show, setShow] = useState(false);
  const extraData = data.extra;

  return (
    <S.Container>
      {!show && (
        <>
          <S.Button onClick={() => setShow(true)}>Product Selector</S.Button>
          <S.Button>
            <a href={path} target="_blank" rel="noreferrer">
              Open
            </a>
          </S.Button>
        </>
      )}
      {show && (
        <Modal>
          <S.DataWrapper onClick={() => setShow(false)}></S.DataWrapper>
          <S.DataContainer>
            <h3>Subscriptions:</h3>
            <p>
              <u>Discount Code:</u>{' '}
              <strong>{data.subscription.discounts.join(', ')}</strong>
            </p>
            <p>
              <u>Products</u>
            </p>
            <ul>
              {data.subscription.products.map((product, index) => (
                <li key={index} style={{ marginBottom: 20 }}>
                  <strong>{product.checkoutData.title}</strong>
                  <br />
                  {product.checkoutData.variant_id} -{' '}
                  {product.checkoutData.quantity}x -{' '}
                  <span style={PriceStrike}>
                    {data.currency.symbol}
                    {product.checkoutData.perceived_rrp}
                  </span>{' '}
                  {data.currency.symbol}
                  {product.checkoutData.discounted_price}{' '}
                  <small>
                    (Savings: {data.currency.symbol}
                    {(
                      product.checkoutData.perceived_rrp -
                      product.checkoutData.discounted_price
                    ).toFixed(2)}
                    {' - '}
                    {(
                      ((product.checkoutData.perceived_rrp -
                        product.checkoutData.discounted_price) /
                        product.checkoutData.perceived_rrp) *
                      100
                    ).toFixed(0)}
                    %)
                  </small>
                  <br />
                  <strong>
                    Price per chew{' '}
                    <small>({30 * product.checkoutData.quantity} chews)</small>
                  </strong>
                  : {data.currency.symbol}
                  {(
                    product.checkoutData.discounted_price /
                    (30 * product.checkoutData.quantity)
                  ).toFixed(2)}{' '}
                  <small>
                    (Savings: {data.currency.symbol}
                    {(
                      (product.checkoutData.perceived_rrp -
                        product.checkoutData.discounted_price) /
                      (30 * product.checkoutData.quantity)
                    ).toFixed(2)}
                    )
                  </small>
                  {product.freeGiftData.variantId && (
                    <>
                      <br />
                      <strong>Free Gift</strong>:{' '}
                      {product.freeGiftData.variantId} - {data.currency.symbol}
                      {product.free_gift_value}
                    </>
                  )}
                  {product.discount_code_override.length > 0 && (
                    <>
                      <br /> <strong>Discount Code Override</strong>:{' '}
                      {product.discount_code_override.join(', ')}
                    </>
                  )}
                  {product.higher_initial_discount.length > 0 && (
                    <>
                      <br /> <strong>Higher Initial Discount</strong>:{' '}
                      {product.higher_initial_discount.join(', ')}
                    </>
                  )}
                  {product.rebill_discount.length > 0 && (
                    <>
                      <br /> <strong>Rebill Code</strong>:{' '}
                      {product.rebill_discount.join(', ')}
                    </>
                  )}
                  {product.priceSettingTag && (
                    <>
                      <br /> <strong>Price Setting Tag</strong>:{' '}
                      {product.priceSettingTag}
                    </>
                  )}
                  {product.upsell_url && (
                    <>
                      <br /> <strong>Upsell URL Override</strong>:{' '}
                      <small>
                        <a
                          href={`${product.upsell_url}/token`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {product.upsell_url}
                        </a>
                      </small>
                    </>
                  )}
                </li>
              ))}
            </ul>
            <p>
              <u>Bump Offer</u>
            </p>
            <ul>
              {data.subscription.bumpoffers.map((product, index) => (
                <li key={index}>
                  <strong>{product.checkoutData.title}</strong>
                  <br />
                  {product.checkoutData.variant_id} -{' '}
                  {product.checkoutData.quantity}x -{' '}
                  <span style={PriceStrike}>
                    {data.currency.symbol}
                    {product.checkoutData.perceived_rrp}
                  </span>{' '}
                  {data.currency.symbol}
                  {product.checkoutData.discounted_price}{' '}
                  <small>
                    (Savings: {data.currency.symbol}
                    {(
                      product.checkoutData.perceived_rrp -
                      product.checkoutData.discounted_price
                    ).toFixed(2)}
                    {' - '}
                    {(
                      ((product.checkoutData.perceived_rrp -
                        product.checkoutData.discounted_price) /
                        product.checkoutData.perceived_rrp) *
                      100
                    ).toFixed(0)}
                    %)
                  </small>{' '}
                  <br /> <strong>Discount Code</strong>:{' '}
                  {product.productRef.product.discountCode}
                  {product.higher_initial_discount && (
                    <>
                      <br /> <strong>Higher Initial Discount</strong>:{' '}
                      {product.higher_initial_discount}
                    </>
                  )}
                  {product.rebill_discount && (
                    <>
                      <br /> <strong>Rebill Code</strong>:{' '}
                      {product.rebill_discount}
                    </>
                  )}
                </li>
              ))}
            </ul>
            <hr />
            <h3>Onetime:</h3>
            <p>
              <u>Discount Code:</u>{' '}
              <strong>{data.onetime.discounts.join(', ')}</strong>
            </p>
            <p>
              <u>Products</u>
            </p>
            <ul>
              {data.onetime.products.map((product, index) => (
                <li key={index} style={{ marginBottom: 20 }}>
                  <strong>{product.checkoutData.title}</strong>
                  <br />
                  {product.checkoutData.variant_id} -{' '}
                  {product.checkoutData.quantity}x -{' '}
                  <span style={PriceStrike}>
                    {data.currency.symbol}
                    {product.checkoutData.perceived_rrp}
                  </span>{' '}
                  {data.currency.symbol}
                  {product.checkoutData.discounted_price}{' '}
                  <small>
                    (Savings: {data.currency.symbol}
                    {(
                      product.checkoutData.perceived_rrp -
                      product.checkoutData.discounted_price
                    ).toFixed(2)}
                    {' - '}
                    {(
                      ((product.checkoutData.perceived_rrp -
                        product.checkoutData.discounted_price) /
                        product.checkoutData.perceived_rrp) *
                      100
                    ).toFixed(0)}
                    %)
                  </small>
                  <br />
                  <strong>
                    Price per chew{' '}
                    <small>({30 * product.checkoutData.quantity} chews)</small>
                  </strong>
                  : {data.currency.symbol}
                  {(
                    product.checkoutData.discounted_price /
                    (30 * product.checkoutData.quantity)
                  ).toFixed(2)}{' '}
                  <small>
                    (Savings: {data.currency.symbol}
                    {(
                      (product.checkoutData.perceived_rrp -
                        product.checkoutData.discounted_price) /
                      (30 * product.checkoutData.quantity)
                    ).toFixed(2)}
                    )
                  </small>
                  {product.upsell_url && (
                    <>
                      <br /> <strong>Upsell URL Override</strong>:{' '}
                      <small>
                        <a
                          href={`${product.upsell_url}/token`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {product.upsell_url}
                        </a>
                      </small>
                    </>
                  )}
                </li>
              ))}
            </ul>
            <p>
              <u>Bump Offer</u>
            </p>
            <ul>
              {data.onetime.bumpoffers.map((product, index) => (
                <li key={index}>
                  <strong>{product.checkoutData.title}</strong>
                  <br />
                  {product.checkoutData.variant_id} -{' '}
                  {product.checkoutData.quantity}x -{' '}
                  <span style={PriceStrike}>
                    {data.currency.symbol}
                    {product.checkoutData.perceived_rrp}
                  </span>{' '}
                  {data.currency.symbol}
                  {product.checkoutData.discounted_price}{' '}
                  <small>
                    (Savings: {data.currency.symbol}
                    {(
                      product.checkoutData.perceived_rrp -
                      product.checkoutData.discounted_price
                    ).toFixed(2)}
                    {' - '}
                    {(
                      ((product.checkoutData.perceived_rrp -
                        product.checkoutData.discounted_price) /
                        product.checkoutData.perceived_rrp) *
                      100
                    ).toFixed(0)}
                    %)
                  </small>{' '}
                  <br /> <strong>Discount Code</strong>:{' '}
                  {product.productRef.product.discountCode}
                  {product.higher_initial_discount && (
                    <>
                      <br /> <strong>Higher Initial Discount</strong>:{' '}
                      {product.higher_initial_discount}
                    </>
                  )}
                  {product.rebill_discount && (
                    <>
                      <br /> <strong>Rebill Code</strong>:{' '}
                      {product.rebill_discount}
                    </>
                  )}
                </li>
              ))}
            </ul>
            <p>
              <u>Upsell URL:</u>{' '}
              <strong>
                <a
                  href={`${data.upsell}/token`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.upsell}
                </a>
              </strong>
            </p>
            <p>
              <u>Currency:</u>{' '}
              <strong>
                {data.currency.code} - {data.currency.symbol}
              </strong>
            </p>
            <p>
              <u>Extra:</u>
            </p>
            <ul>
              {Object.keys(extraData).map(key => (
                <li key={key}>
                  {key}: <strong>{extraData[key]}</strong>
                </li>
              ))}
            </ul>
          </S.DataContainer>
        </Modal>
      )}
    </S.Container>
  );
}

export default ProductSelectorPreview;
