import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: row;
`;

export const Button = styled.div`
  cursor: pointer;
  border: 1px solid #000;
  margin-left: 10px;
  border-bottom: 0;
  padding: 10px 10px 5px;
  background: #001c72;
  border-radius: 10px 10px 0 0;
  color: #fff;
  & > a {
    color: #fff !important;
    text-decoration: none;
  }
`;

export const DataWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
`;

export const DataContainer = styled.div`
  position: fixed;
  width: 500px;
  height: 500px;
  background: #fff;
  border: 1px solid #000;
  z-index: 9999;
  border-radius: 10px;
  box-shadow: 0 0 10px #5d5d5d;
  top: calc(50% - 280px);
  left: calc(50% - 280px);
  padding: 30px;
  overflow-y: auto;
  @media screen and (max-width: 500px) {
    width: 300px;
    left: calc(50% - 180px);
  }
`;

export const PriceStrike = styled.span`
  text-decoration: line-through;
`;
